import axios from "axios";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { Build, Unit, UnitLine } from "../../types/project";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import { Source, USDollar } from "./Common";

const HEADERS = [
  "Installed",
  "Part No.",
  "Summary",
  "Name",
  "Version",
  "Qty",
  "Price",
  "Note",
  "Source",
];

export function BuildBom({ build }: { build: Build }) {
  const [bom, setBom] = useState<UnitLine[] | undefined>(undefined);

  useEffect(() => {
    axios
      .get(`/bom/?serial=${build.serial}`)
      .then((res) => setBom(res.data))
      .catch(console.error);
  }, [build]);

  if (!bom) return <></>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {HEADERS.map((head) => (
            <TableHeader key={head}>{head}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {bom.map((l) => (
          <TableRow key={l.part.id}>
            <TableCell className="w-28">
              {l.installed && (
                <ReactTimeAgo date={new Date(l.installed)} locale="en-US" />
              )}
              {!l.installed && "n/a"}
            </TableCell>
            <TableCell>{l.part.num}</TableCell>
            <TableCell>
              {l.part.summary !== "null" ? l.part.summary : ""}
            </TableCell>
            <TableCell>{l.part.name}</TableCell>
            <TableCell>{l.part.version}</TableCell>
            <TableCell>{l.qty}</TableCell>
            <TableCell>{USDollar.format(l.part.price)}</TableCell>
            <TableCell>{l.part.note}</TableCell>
            <TableCell>
              <Source url={l.part.url} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function UnitBom({ unit }: { unit: Unit }) {
  return (
    <div>
      {unit.builds.map((build) => (
        <div
          key={build.serial}
          className="grow lg:rounded-lg lg:bg-white lg:p-4 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10"
        >
          <BuildBom build={build} />
        </div>
      ))}
    </div>
  );
}
