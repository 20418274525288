import axios from "axios";
import { useEffect, useState } from "react";
import { Design, VersionLine } from "../../types/project";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import { Source, USDollar } from "./Common";

const HEADERS = [
  "Part No.",
  "Summary",
  "Name",
  "Version",
  "Qty",
  "Price",
  "Note",
  "Source",
];

export default function DesignBom({ design }: { design: Design }) {
  const [bom, setBom] = useState<VersionLine[] | undefined>(undefined);

  useEffect(() => {
    const currentDesign = design.versions[0];
    axios
      .get(`/bom/?diff_id=${currentDesign.diff}`)
      .then((res) => setBom(res.data));
  }, [design]);

  if (!bom) return <></>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {HEADERS.map((head) => (
            <TableHeader key={head}>{head}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {bom.map((l) => (
          <TableRow key={l.part.id}>
            <TableCell>{l.part.num}</TableCell>
            <TableCell>
              {l.part.summary !== "null" ? l.part.summary : ""}
            </TableCell>
            <TableCell>{l.part.name}</TableCell>
            <TableCell>{l.part.version}</TableCell>
            <TableCell>{l.qty}</TableCell>
            <TableCell>{USDollar.format(l.part.price)}</TableCell>
            <TableCell>{l.part.note}</TableCell>
            <TableCell>
              <Source url={l.part.url} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
